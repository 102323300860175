import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { navigate } from 'gatsby';
import { logout } from '../state/industry/industrySlice';
import { resetDiscountCode } from '../state/cart/cartSlice';
import { reset } from '../lib/analytics';
import { Box } from 'grommet';

import Loading from '../components/Loading';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    dispatch(resetDiscountCode());
    reset();
    navigate('/');
  }, []);

  return (
    <Box pad="large" align="center" justify="center" fill>
      <Loading />
    </Box>
  );
};

Logout.propTypes = {
  location: PropTypes.object,
};

export default Logout;
